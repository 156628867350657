<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Service" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Alias *"
                  v-model="alias"
                  @input="delete errors.alias"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                />
                <va-input
                  label="Breadcrumbs text *"
                  v-model="breadcrumbsText"
                  @input="delete errors.breadcrumbs_text"
                  :error="!!errors.breadcrumbs_text"
                  :error-messages="errors.breadcrumbs_text"
                />
                <va-input
                  label="Label *"
                  v-model="label"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Description"
                  type="textarea"
                  v-model="description"
                  @input="delete errors.description"
                  :error="!!errors.description"
                  :error-messages="errors.description"
                />
                <va-card class="mb-3" title="Image">
                  <div v-if="imageExisting">
                    <a style="display: block;" target="_blank" :href="imageExisting"><img style="max-width: 400px;" :src="imageExisting"></a>
                    <div><va-button small color="danger" @click="imageExisting = deleteImage(imageId) ? '' : imageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.image"
                    v-model="image"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', 'ServiceImage', 'Services[image]', image[0])">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Image Mobile">
                  <div v-if="imageMobileExisting">
                    <a style="display: block;" target="_blank" :href="imageMobileExisting"><img style="max-width: 400px;" :src="imageMobileExisting"></a>
                    <div><va-button small color="danger" @click="imageMobileExisting = deleteImage(imageMobileId) ? '' : imageMobileExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.imageMobile"
                    v-model="imageMobile"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('imageMobile', 'ServiceImageMobile', 'Services[imageMobile]', imageMobile[0])">Завантажити</va-button>
                  <div v-if="errors.imageMobile" style="color: red;">{{ errors.imageMobile[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Thumb Image">
                  <div v-if="thumbImageExisting">
                    <a style="display: block;" target="_blank" :href="thumbImageExisting"><img style="max-width: 400px;" :src="thumbImageExisting"></a>
                    <div><va-button small color="danger" @click="thumbImageExisting = deleteImage(thumbImageId) ? '' : thumbImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.thumbImage"
                    v-model="thumbImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('imageThumb', 'ServiceImageThumb', 'Services[imageThumb]', thumbImage[0])">Завантажити</va-button>
                  <div v-if="errors.thumbImage" style="color: red;">{{ errors.thumbImage[0] }}</div>
                </va-card>
                <va-card class="mb-3" title="Image description">
                  <div v-if="imageDescriptionExisting">
                    <a style="display: block;" target="_blank" :href="imageDescriptionExisting"><img style="max-width: 400px;" :src="imageDescriptionExisting"></a>
                    <div><va-button small color="danger" @click="imageDescriptionExisting = deleteImage(imageDescriptionId) ? '' : imageDescriptionExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.imageDescription"
                    v-model="imageDescription"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('imageDescription', 'ServiceImageDescription', 'Services[imageDescription]', imageDescription[0])">Завантажити</va-button>
                  <div v-if="errors.imageDescription" style="color: red;">{{ errors.imageDescription[0] }}</div>
                </va-card>
                <div style="margin-bottom: 15px;">
                  <span>Content</span>
                  <ckeditor v-model="content" :editor="editor" :config="editorConfig"></ckeditor>
                  <div v-if="errors.content" style="color: red;">{{ errors.content[0] }}</div>
                </div>
                <va-card
                  title="Faq"
                  style="margin-bottom: 25px;"
                >
                  <va-button style="margin-bottom: 10px;" small @click="addInput">Додати</va-button>
                  <div class="mb-2" v-for="(item, index) in faq" :key="index">
                    <va-card>
                      <va-input
                        label="Question"
                        type="textarea"
                        v-model="faq[index].question"
                      />
                      <div style="margin-bottom: 15px;">
                        <span>Answer</span>
                        <ckeditor v-model="faq[index].answer" :editor="editor" :config="editorConfig"></ckeditor>
                      </div>
                      <va-button style="margin-bottom: 30px;" v-if="faq.length > 0" color="danger" small @click="removeInput(index)">Видалити</va-button>
                    </va-card>
                  </div>
                </va-card>
                <va-toggle label="Menu item" small v-model="is_menu_item"/>
                <va-toggle label="Published" small v-model="published"/>
                <va-input
                  label="Position"
                  v-model.number="position"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Meta Title"
                  v-model="metaTitle"
                  @input="delete errors.metaTitle"
                  :error="!!errors.metaTitle"
                  :error-messages="errors.metaTitle"
                />
                <va-input
                  label="Meta Description"
                  v-model="metaDescription"
                  @input="delete errors.metaDescription"
                  :error="!!errors.metaDescription"
                  :error-messages="errors.metaDescription"
                />
                <va-input
                  label="OG Title"
                  v-model="OgTitle"
                  @input="delete errors.OgTitle"
                  :error="!!errors.OgTitle"
                  :error-messages="errors.OgTitle"
                />
                <va-input
                  label="OG Description"
                  v-model="OgDescription"
                  @input="delete errors.OgDescription"
                  :error="!!errors.OgDescription"
                  :error-messages="errors.OgDescription"
                />
                <va-card class="mb-3" title="OG Image">
                  <div v-if="OgImageExisting">
                    <a style="display: block;" target="_blank" :href="OgImageExisting"><img style="max-width: 400px;" :src="OgImageExisting"></a>
                    <div><va-button small color="danger" @click="OgImageExisting = deleteImage(OgImageId) ? '' : OgImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="OgImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', null, 'SeoEntityService[image]', OgImage[0], 'common\\models\\SeoEntityService')">Завантажити</va-button>
                  <div v-if="errors.image" style="color: red;">{{ errors.image[0] }}</div>
                </va-card>
                <va-input
                  label="seo text title"
                  v-model="seoTextTitle"
                  @input="delete errors.seoTextTitle"
                  :error="!!errors.seoTextTitle"
                  :error-messages="errors.seoTextTitle"
                />
                <div style="margin-bottom: 15px;">
                  <span>seo text</span>
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="seo text see more text"
                  v-model="seoTextSeeMore"
                  @input="delete errors.seoTextSeeMore"
                  :error="!!errors.seoTextSeeMore"
                  :error-messages="errors.seoTextSeeMore"
                />
                <va-input
                  label="Seo Text Hide Text"
                  v-model="seoTextHide"
                  @input="delete errors.seoTextHide"
                  :error="!!errors.seoTextHide"
                  :error-messages="errors.seoTextHide"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import CKEditor from '@ckeditor/ckeditor5-vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      alias: '',
      breadcrumbsText: '',
      label: '',
      description: '',
      image: [],
      imageId: '',
      imageExisting: '',
      imageMobile: [],
      imageMobileId: '',
      imageMobileExisting: '',
      thumbImage: [],
      thumbImageId: '',
      thumbImageExisting: '',
      imageDescription: [],
      imageDescriptionId: '',
      imageDescriptionExisting: '',
      content: '',
      faq: [],

      position: '',
      published: false,
      is_menu_item: false,

      sign: '',

      errors: [],

      seoId: null,
      metaTitle: '',
      metaDescription: '',
      OgTitle: '',
      OgDescription: '',
      OgImage: [],
      OgImageId: '',
      OgImageExisting: '',
      seoTextTitle: '',
      seoText: '',
      seoTextSeeMore: '',
      seoTextHide: '',

      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_1LK_API_URL}/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      tabTitles: ['Main', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    addInput () {
      this.faq.push({
        question: '',
        answer: '',
      })
    },
    removeInput (index) {
      this.faq.splice(index, 1)
    },
    uploadImage (attr, entityAttribute, param, file, model = 'backend\\modules\\services\\models\\Services') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (entityAttribute === null) {
        formData.append('id', this.seoId ? this.seoId : '0')
      } else {
        formData.append('entity_attribute', entityAttribute)
        if (this.$attrs.id) {
          formData.append('id', this.$attrs.id)
        } else {
          formData.append('sign', this.sign)
        }
      }

      axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/upload?lang=${this.locale}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_1LK_API_URL}/admin/upload/${id}?lang=${this.locale}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        alias: this.alias,
        label: this.label,
        position: this.position,
        description: this.description,
        published: this.published ? 1 : 0,
        is_menu_item: this.is_menu_item ? 1 : 0,
        breadcrumbs_text: this.breadcrumbsText,
        content: this.content,
        faqArray: this.faq,

        SeoEntityService: {
          title: this.metaTitle,
          description: this.metaDescription,
          og_title: this.OgTitle,
          og_description: this.OgDescription,
          seo_text_title: this.seoTextTitle,
          seo_text: this.seoText,
          seo_text_see_more_text: this.seoTextSeeMore,
          seo_text_hide_text: this.seoTextHide,
        },

        sign: this.sign ? this.sign : null,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_1LK_API_URL}/admin/service/${this.$attrs.id}?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: '1lk-service' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_1LK_API_URL}/admin/service?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: '1lk-service' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_1LK_API_URL}/admin/service/${this.$attrs.id}?lang=${this.locale}`)
          .then(response => {
            console.log(response.data)
            this.alias = response.data.alias
            this.label = response.data.label
            this.position = response.data.position
            this.description = response.data.description
            this.published = !!response.data.published
            this.is_menu_item = !!response.data.is_menu_item
            this.breadcrumbsText = response.data.breadcrumbs_text
            this.content = response.data.content
            this.faq = response.data.faq

            this.imageExisting = response.data.image ? process.env.VUE_APP_1LK_ADMIN + response.data.image : ''
            this.imageId = response.data.imageId
            this.imageMobileExisting = response.data.imageMobile ? process.env.VUE_APP_1LK_ADMIN + response.data.imageMobile : ''
            this.imageMobileId = response.data.imageMobileId
            this.thumbImageExisting = response.data.thumbImage ? process.env.VUE_APP_1LK_ADMIN + response.data.thumbImage : ''
            this.thumbImageId = response.data.thumbImageId
            this.imageDescriptionExisting = response.data.imageDescription ? process.env.VUE_APP_1LK_ADMIN + response.data.imageDescription : ''
            this.imageDescriptionId = response.data.imageDescriptionId

            this.seoId = response.data.seo.id
            this.metaTitle = response.data.seo.title
            this.metaDescription = response.data.seo.description
            this.OgTitle = response.data.seo.og_title
            this.OgDescription = response.data.seo.og_description
            this.seoTextTitle = response.data.seo.seo_text_title
            this.seoText = response.data.seo.seo_text ? response.data.seo.seo_text : ''
            this.seoTextSeeMore = response.data.seo.seo_text_see_more_text
            this.seoTextHide = response.data.seo.seo_text_hide_text

            this.OgImageExisting = response.data.seo.og_image ? process.env.VUE_APP_1LK_ADMIN + response.data.seo.og_image : null
            this.OgImageId = response.data.seo.og_image_id
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
